@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  /* html,*/
  body{
      margin: 0;
      /* display: flex; */
      /* width: 1152px; */
      height: 100%; 
      /* box-sizing: border-box; */
      color: var(--on-surface);
      /* overflow: hidden; */
      position: relative;

  }
}
body {
  margin: 0;
  font-family: 'Poppins';

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Poppins'
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins';
}


body {
  /* background-image: url('../src/assets/images/wood-bg.png'); */
   /* background-image: url('../src/assets/images/background.png');  */
  background-repeat: no-repeat;


   background-position:100% 100%;
}

/* #cta {p
  background-image: url('./assets/images/bg-simplify-section-desktop.svg');
  background-repeat: no-repeat;
} */

@media (max-width: 576px) {
  body {
    background-position: 50px -50px;
    background-size: 500px;
  }

  /* #cta {
    background-image: url('./assets/images/bg-simplify-section-mobile.svg');
  } */
}

/* Hamburger Menu */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background: #000;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}
.underline{
  text-decoration-line: underline;

}
.border {
  border-width: 0.1px;
}
.border-black {
  --tw-border-opacity: .3;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}


